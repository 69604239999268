
// @import "variables";
// @import "fonts";
// @import "mixins";

// Common
@import "variables";
@import "global";
@import "layouts/modules";

@import "fonts";

@import "header"; 
@import "footer";    
@import "layouts/cookies";    
   
@import "accordion";   
 
@import "layouts/preistraeger";  
    
@import "layouts/frontpage";    
@import "layouts/galerien";   
@import "layouts/nominierungen";    


@import "layouts/button";      
  
// @import "blocks/nominierungen";  

// @import "layouts/kontakt";     
     
                       