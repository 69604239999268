.show-mobile{
    display:none;
        }
.show-desk{
        display:block;    
        }
        .overlay{
            background-color:rgba(0, 0, 0, .3);
            position: fixed;
            z-index:1;
            width:100%;
            left:0;
            right:0;
            top:0;
            bottom:0;
            display:none;
        }
        
        .overlay-visible{
            display:block;
        }   

header{

 
    width:100%;
    height:270px;;
    position:relative;

    @media screen and (max-width: $mobilesize) {
        height: 175px !important; 
  
      }

  


    .logo{
        background-image:url('/wp-content/themes/axel-springer-preis/img/logo-asp-plain.png');
        background-size:contain;
        background-repeat: no-repeat;
        background-position:center;
        display:block;
        height: 160px;
        width:70%; 
        margin:auto;
        // float:left;
        position:relative;
        margin-top:20px;

       
    }

    nav{
     
        border-bottom:1px solid $lightgrey;
        border-top:1px solid $lightgrey;
        width: 100%;
        margin-top:30px;
        margin-bottom:30px;
        text-align:center;
        ul{
       
            padding:0px;
            li{
                float:none;
                display:inline;
                list-style: none;
                margin:30px;
                &:first-child{
                    margin-left:0px;
                }
                &:last-child{
                    margin-right:0px;
                }

                a{
                 
                    &:hover{
                        color:$contrastcolor;
                    }
                }
               
            }
            .current-menu-item {
                a{
                color:$contrastcolor !important;
                }
            }
        }
    }
        #searchform{
            float:right;
            margin-top: 22px;
            
        }
    }
    



.searchfield{
    // background-color: #fafafa;
    border:none;
    outline:none;
    text-indent:10px;
    height:35px;
    font-size:11pt;
    letter-spacing: 1pt;
    text-align:right;
    input{
        letter-spacing: 1pt;
        font-size:11pt;
        font-family: "calluna-sans", sans-serif;
    }
}



.hamburger {
    padding: 15px 15px;
    padding-left: 0px;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible; 
    position:relative;
}
    .hamburger:hover {
      opacity: 0.7; }
  
  .hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative; }
  
  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px; }
    .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
      width: 40px;
      height: 2px;
      background-color: #000;
      border-radius: 4px;
      position: absolute;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease; }
    .hamburger-inner::before, .hamburger-inner::after {
      content: "";
      display: block; }
    .hamburger-inner::before {
      top: -10px; }
    .hamburger-inner::after {
      bottom: -10px; }

      .hamburger--spring .hamburger-inner {
        top: 2px;
        transition: background-color 0s 0.13s linear; }
        .hamburger--spring .hamburger-inner::before {
          top: 10px;
          transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
        .hamburger--spring .hamburger-inner::after {
          top: 20px;
          transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
      
      .hamburger--spring.is-active .hamburger-inner {
        transition-delay: 0.22s;
        background-color: transparent; }
        .hamburger--spring.is-active .hamburger-inner::before {
          top: 0;
          transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
          transform: translate3d(0, 10px, 0) rotate(45deg); }
        .hamburger--spring.is-active .hamburger-inner::after {
          top: 0;
          transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
          transform: translate3d(0, 10px, 0) rotate(-45deg); }


          .hamburger{ 
            display: none;
        }

    .show{
            display:block !important;
}
        
@media screen and (max-width: $desktopsize) {
                #wrapper{
                    width:90%;
                }
        }
@media screen and (max-width: 850px) {
    .asa-logo{
        margin-left: auto !important;
        margin:auto;
    }
   
    .hamburger{ 
        display: block;
        position:relative;
        
        margin: auto;
        width: 30px;
        
    }
    // header{
    //     nav{
    //         background-color:$white;
            
    //         position:absolute;
    //         top:200px;
    //         left:0;
    //         right:0;
    //         ul{
    //             li{
    //                 display:block;
    //                 text-align:center;
    //                 a{
    //                     color:$contrastcolor;
    //                 }
    //             }
    //         }
    //     }
    // }

    .show-mobile{
        display:block;
    }
    .show-desk{
        display:none;
    }

    // .dropdown{    
    //     display: none !important;
    // }

    header{
       height:auto;
       nav{
           display:none;
       }

       .social-wrapper{
        margin:0;
        float:none;
        margin:auto;
        margin-top:20px;
       
      
       }

    //    .icon-fb{
    //        margin:0;
    //        float:none;
    //        margin:auto;
    //        margin-top:20px;
    //    }
       .logo{

        width:90%;
     

       
    }
        nav.show{
          
            z-index:100;
            position:absolute;
            top: 175px; 
            width: 100%;
            background-color:white;
            ul{
                width: 100%;
                li{
                    display:block;
                    width:100%;
                    text-align:center;
                    margin-left:0px !important;
                    margin-right:0px !important;
                    a{
                        font-size: 18pt;
                        
                    }
                 
                }
            }
        }
    }

}



@media screen and (max-width: $mobilesize) {
header{

    .logo{
        // background-image:url('/wp-content/themes/axel-springer-preis/img/logo-mobile.jpg');
        background-image:url('/wp-content/themes/axel-springer-preis/img/logo-asp-plain.png');
        display:block;
        height: 120px;
        width: 100%;

        margin:auto;
        // float:left;
        position:relative;
        margin-top:20px;

       
    }
}
}
