.home {



  nav {
    margin-bottom: 0;
  }

  // header {
  //   height: 240px;
  // }

    
  h1.has-background{
    @media screen and (max-width: $tabletsize) {
    padding:10px;
    padding-top:20px;
    font-size:18pt;
    line-height:22pt;
}

} 


  /*link to yt video*/
  .btn-cta {
    background-color: #f0f;
    padding: 20px;
    color: #1900fe;
    text-transform: uppercase;
    align-self: center;

    font-size: 30pt;

    @media screen and (max-width: $desktopsize) {
      font-size: 20pt !important;
    }

    @media screen and (max-width: $tabletsize) {
    }

    @media screen and (max-width: $mobilesize) {
      font-size: 10pt !important;
    }
  }

  .intro-text {
    padding-bottom: 20px !important;
    width: 80% !important;
    margin: auto !important;
    display: block;
    line-height: 32pt !important;

    @media screen and (max-width: $desktopsize) {
      line-height: 23pt !important;
    }

    @media screen and (max-width: $tabletsize) {
      line-height: 23pt !important;
      padding-bottom: 30px !important;
    }

    @media screen and (max-width: $mobilesize) {
      line-height: 19pt !important;
    }
  }

  /*end link to yt video*/







  .ticker {
    // font-size:14pt;
    background-color: #f0f;
    color: white;
    margin-top: 30px;

    font-size: 40pt;

    text-align: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;

    padding-top: 100px;
    padding-bottom: 100px;

    @media screen and (max-width: $tabletsize) {
      font-size: 20pt;
    }

    a {
      //color:$contrastcolor;
      color: #00ff01;
      font-weight: bold;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media screen and (max-width: $desktopsize) {
  }

  @media screen and (max-width: $tabletsize) {
  }
}




  /*header graphic*/
  .collage{
    display:block;
    width:100%;
    height:auto;

    margin-top: 50px;

    img{ 
      width:100%;
      height:auto;
    }
    @media screen and (max-width: $tabletsize) {
    display:none;
    }
  }
  .collage-mobile{
    display:none;
    width:100%;
    height:auto;

    margin-top: 50px;

    img{
      width:100%;
      height:auto;
    }
    @media screen and (max-width: $tabletsize) {
      display:block;
    }
  }
  
/**** COUNTDOWN ****/

.countdown{
  margin-bottom: 10px;
}

.counter-wrapper {
  background-color: blue;
  padding-top: 20px;
  padding-bottom: 30px;
  font-size: 40pt; 
  text-align: center;
  color: #ff00ff !important;
  display: flex;
  flex-direction: column; 
  margin-top: 30px;
  padding-top: 20px;
  padding-bottom: 0px;
  #counter-wrapper-inner {
    width: 80%;
    max-width: 550px;
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  a {
    //color:$contrastcolor;

    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }
  
  h2 {
    // color: #00ff01 !important;
    color: white !important;
    font-size: 20pt;
    margin:auto;
    // margin-bottom: 10px;
    font-weight:normal;
    width:90%;

    span {
      font-size: 20pt;
      font-weight: 200;
      // letter-spacing: 8pt;
      // font-weight:bold;
      // text-transform: uppercase;

      @media screen and (max-width: $desktopsize) {
        font-size: 20pt !important;
      }

      @media screen and (max-width: $tabletsize) {
        font-size: 15pt !important;
      }
    }

    @media screen and (max-width: $desktopsize) {
      font-size: 20pt !important;
    }

    @media screen and (max-width: $tabletsize) {
      font-size: 15pt !important;
    }
  } 
  h3{
    //color: #00ff01 !important;
    color: white !important;
    // font-weight:bold;
    margin-top:30px;
    font-size: 20pt;
  }
}

.count-wrap{
  display:flex;
  justify-content:space-between;
  width:100%;
  }
  .count-single{
    // width:25%;
    display:flex;
    flex-direction:column;
    font-weight: bold !important;
    margin-right: 5px;
    margin-left: 10px;
    // color: white;
    color:#ff00ff;
    // color: #1900fe; 
    // color: #00ff01;
    font-size: 20pt;
    position: relative;

    @media screen and (max-width: 1400px) {
      font-size: 25pt;
    }

    @media screen and (max-width: $desktopsize) {
      font-size: 20pt;
    }

    @media screen and (max-width: $tabletsize) {
      font-size: 18pt;
    }

    @media screen and (max-width: $mobilesize) {
      font-size: 16pt;
      &:nth-child(6){
        display:none;
      }
      &:nth-child(7){
        display:none;
      }
    }

    span{
      font-size:14pt;
      color:#ff00ff;
      @media screen and (max-width: 1400px) {
       
      }
  
      @media screen and (max-width: $desktopsize) {
        font-size:14pt;
      }
  
      @media screen and (max-width: $tabletsize) {
        font-size:12pt;
      }
  
      @media screen and (max-width: $mobilesize) {
        font-size: 7pt;
      }
   
    }





    // &:after {
    //   content: ":";
    //   display: block;
    //   color: #1900fe;
    //   font-size: 80pt !important;
    //   position: absolute;
    //   right: -10%;
    //   bottom: 0;

    //   @media screen and (max-width: 1400px) {
    //     font-size: 70pt !important;
    //   }

    //   @media screen and (max-width: $desktopsize) {
    //     font-size: 48pt !important;
    //   }

    //   @media screen and (max-width: $tabletsize) {
    //     font-size: 35pt !important;
    //   }

    //   @media screen and (max-width: $mobilesize) {
    //     font-size: 16pt !important;
    //   }
    // }

  }

/**** END COUNTDOWN ****/

h2.islive{
  font-weight: bold !important;
 margin:auto;
 margin-top: 20px;
 text-align:center;
  color: white;
  font-size: 30pt;
  @media screen and (max-width: 1400px) {
    font-size: 25pt;
  }

  @media screen and (max-width: $desktopsize) {
    font-size: 20pt;
  }

  @media screen and (max-width: $tabletsize) {
    font-size: 18pt;
  }
}

.btn-live{
  background-color:#00ff01; 
  color:blue;
  text-decoration:none !important;
  display:flex;
  justify-content: center;
  align-items:center; 
  width:280px; 
  height:48px; 

  border-radius:0px;
  font-size:20pt; 
  margin:auto;
  // margin-top:30px;
  margin-bottom:20px;
  .btn-live-inner{
    width:260px; 
    display:flex;
    justify-content: space-between;
    align-items:center;  
  }
  &:hover{
    background-color:#f0f; 

  }
  .live-icon{

    position: relative;

    background-color: blue;
    width: 10px;
    height: 10px;
    margin: auto;
    border: 1px solid rgba(black, .1);
    border-radius: 50%;
    z-index: 1;
    &:before{
      content: '';
      display: block; 
      position: absolute;
      background-color: rgba(blue,.6);
      width: 100%;
      height: 100%;
      border-radius: 50%;
      animation: live 2s ease-in-out infinite;
      z-index: -1;
    }
  }
@keyframes live {
  0% {
    transform: scale(1,1);
  }
  100% {
    transform: scale(3.5,3.5);
    background-color: rgba(red,0);
  }
}
}