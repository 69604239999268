$mobilesize:600px;
$tabletsize:768px;
$middesktop:1024px;
$desktopsize:1200px;

$white:white;
$black:#000;
$grey:#979797;
$lightgrey:#ddd;
// $contrastcolor: #0180c7;
$contrastcolor: #1900FE; 
$pink: #FF00FF; 