.awards{
    text-align: center;
    padding-top:50px;
    padding-bottom:50px;
    width: 100%;
    h2{
        font-size:16pt;
        text-transform: none;
        margin-top:0;
        font-weight:600;
    }
    h3{
        margin-bottom:0;
        font-size:12pt;
        font-weight:200;
        text-transform: none;
        margin-bottom:10px;
    }
    img{
        width:100px;
        border-radius:50%;
        height:auto;
        display:block;
        margin: auto;
      
        
    }
    .section{
        padding-bottom:50px;
        padding-top:50px;
    }
    .custom-col{
        width:20%;
        display: inline-block;
        vertical-align:top;
        float:none;
        margin-left: 5%;
        margin-right: 5%;
        span{
            width:auto;
            cursor:pointer;
            position: relative;
            display: block;
            img{
                width:90% !important;
                border:4px solid white;
                transition: all .2s ease-in-out;  
            }
            &:hover{
                img{
                border:4px solid #FF00FF;
                }
            }
        }
     
    }
    .custom-acc-link{
        opacity:1;
        transition: all .25s ease-in-out;
        -moz-transition: all .25s ease-in-out;
        -webkit-transition: all .25s ease-in-out;
    }
    .content-acc-global{
      
        text-align:left;
        width:60%;
        margin:auto;
        img{ 
            border-radius:0;
            height:auto;
            width:100%;
         
        } 
        p{
            margin:0;
        }
        .content-left{
            width:30%;
            float:left;

            .date,
            .medium{
                text-align:center;
                font-size:9pt;
                margin-bottom:0;
                margin-top:0;
                font-weight:200;
                line-height: normal;
                width: 100%; 
            }
      
            .logo-image {
                width:60%;
                margin:auto;
                margin-top:20px;
                margin-bottom:20px;
            }
          

            img{
         
                margin:auto;
                
            }
        } 
        .content-right{
            width:60%;
            float:right;
            margin-right:30px;
          
        } 


    }

    .dim{
        opacity:.3;
    }

    .gold{
        background-image:url('/wp-content/themes/axel-springer-preis/img/icon-gold.svg');

        background-repeat: no-repeat;
        background-position:center;
        background-size: contain;
        display:block; 
        width:50px;
        height:50px;
        position:absolute;
        z-index:1;
        right:0;
        bottom:0;
    }

    .silver{
        background-image:url('/wp-content/themes/axel-springer-preis/img/icon-silber.svg');

        background-repeat: no-repeat;
        background-position:center;
        background-size: contain;
        display:block; 
        width:50px;
        height:50px;
        position:absolute;
        z-index:1;
        right:0;
        bottom:0;
    }
    .bronze{
        background-image:url('/wp-content/themes/axel-springer-preis/img/icon-bronze.svg');

        background-repeat: no-repeat;
        background-position:center;
        background-size: contain;
        display:block; 
        width:50px;
        height:50px;
        position:absolute;
        z-index:1;
        right:0;
        bottom:0;
    }
 
}



.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 1;
}

.dropdown:hover .dropdown-content {
    display: block;
}


.dropdown{
   a{
    color: #000;
    font-family: "Aller",sans-serif;
    font-weight: 500;
    text-align: center;
    font-size: 40pt;
    }
    span{
        cursor:pointer;
        &:after{
            content: '';
            position: absolute;
            right: -40px;
            top: 25px;
            background-image: url(/wp-content/themes/axel-springer-preis/img/dropdown-arrow.svg);
            background-repeat: no-repeat;
            background-position: top;
            background-size: contain;
            display: block;
            width: 30px;
            height: 60px;
            }
    }
}


@media screen and (max-width: $tabletsize) {
    .dropdown{
        a{
         font-size: 28pt;
         }
         span{
             cursor:pointer;
             &:after{
                 content: '';
                 position: absolute;
                 right: -30px;
                 top: 18px;
                 background-image: url(/wp-content/themes/axel-springer-preis/img/dropdown-arrow.svg);
                 background-repeat: no-repeat;
                 background-position: top;
                 background-size: contain;
                 display: block;
                 width: 20px;
                 height: 30px;
                 }
         }
     }
}

@media screen and (max-width: $middesktop) {
.awards {
    .content-acc-global {
    text-align: left;
    width: 100%;
    margin: auto;
    }
}
}

@media screen and (max-width: $tabletsize) {
    .awards .content-acc-global {
        text-align: left;
        width: 100%;
        margin: auto;
        h2{
            text-align:center;
        }
        .content-left{
            width:100%;
        }
        .content-right{
            width:100%;
            margin:0;
            margin-top:50px;
        }
    }
}

@media screen and (max-width: $mobilesize) {
    .awards{
        .custom-col {
        width: 30%;
        display: inline-block;
        vertical-align: top;
        float: none;
        margin-left: 1%;
        margin-right: 1%;
         }
    }
.custom-acc-link{
    h3{
        font-size:9pt;
    }
    h2{
        font-size:10pt;
    }
}

}

.btn{
    padding: 0;
    display: block;
    min-width: 140px;
    padding-left: 15px;
    padding-right: 15px;
    height: 45px;
    background-color: $contrastcolor;
    border: 1px solid $contrastcolor;
    color: #fff;
    line-height: 45px;
    display: inline-block;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
    -webkit-transition: background .3s;
    transition: background .3s;
    &:hover{
        padding-top: 0; 
        padding-bottom: 0;
        color: $contrastcolor;
        background-color: #fff;
    }
}