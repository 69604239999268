.galerien {
  #grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    // grid-template-columns: 50% 50%;
    & > div {
      width: 48%;
      margin-bottom: 30px;
    }
  }

  @media screen and (max-width: 600px) {
    #grid {
      flex-direction: column;

      // grid-template-columns: 50% 50%;
      & > div {
        width: 100%;
        margin-bottom: 30px;
      }
    }
  }

  img {
    width: 100%;
    height: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    &:hover,
    &:focus {
      outline: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }

  .image-link {
    outline: none;
  }

  .thumb{
position:relative;
z-index:100;
&:hover{
  .icon-bilder{
    transform: scale(1.2) !important; 
  }
}
img{
  display: block;
}
  }
  .thumb-overlay{
    background-color:rgba(0,0,0,.5);
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:100;
    width:100%;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    pointer-events: none;
  
  } 

  .icon-bilder {
    transform: scale(1); 
    transition: all .2s ease-in-out; 
    background-image: url("/wp-content/themes/axel-springer-preis/img/icon-gallery-overlay.svg");
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 40px;
    height: 40px;
    z-index:100;
    display: block;
    // float: right;
  }

  .icon-video {
    background-image: url("/wp-content/themes/axel-springer-preis/img/icon-video.svg");

    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 30px;
    height: 30px;
    display: block;
    float: right;
  }
  .video-container {
    // height: 100%;
    width: 100%;
    .video-container-inner {
      background-color: black;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  h2 {
    font-size: 14pt;
    width: auto;
    float: left;
    margin-top: 5px;
    max-width: calc(100% - 50px);
    //temporär
    font-weight: 200;
  }

  .span_1_of_4 {
    a {
      outline: none;
    }
  }
}

.fancybox-button {
  color: black !important;
  background: rgba(255, 255, 255, 1) !important;

  &:hover {
    background: rgba(255, 255, 255, 0.6) !important;
  }
}
