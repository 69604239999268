.preistraeger{
    img.rounded{
        float:left;
        margin-right:30px;
        width: 25%;
        border-radius: 50%;
        
    }
    img.project-image{
        width:100%;
    }
    .wrap{
    border-bottom: 4px solid $lightgrey;
  
    padding-bottom: 30px;
    margin-bottom: 60px;
    }
    ul{
        list-style:none;
        padding:0;
       
    }
}