html {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: auto;
}

body {
  width: 100%;
  height: 100%;
  font-family: "Open Sans", sans-serif;
  // background-image:url('/wp-content/themes/axel-springer-preis/img/bg.jpg');

  // background-repeat: no-repeat;
  // background-position:center fixed;
  // background-attachment: fixed;

  display: block;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: fixed;
  overflow: auto;
}

#wrapper {
  width: 80%;
  margin: auto;
  overflow: hidden;
  position: relative;
  .inner-wrapper {
    background-color: rgba(255, 255, 255, 0.7);
    margin: 2.5%;
    margin-bottom: 0px;
    padding-bottom: 30px;
    // p{
    //   width:100%;  
    // }
    // #main {
    //   margin: 2.5%;
    //   margin-bottom: 0px;
    //   padding-bottom: 30px;
    // }
  }
}

.content-head {
  margin-bottom: 100px;
}

.blue-bg {
  background-color: $contrastcolor;
}

.social-wrapper{
 
  float: right;
  margin-top: 18px;

 
  display:flex;
  justify-content:space-between;
  width:60px;
  height: 25px;
 }
.icon {
  width: 25px;
  height: 25px;
  display: block;
  // float: right;
  // margin-top: 18px;
  // margin-left: 20px;
}
.icon-fb {
  background-image: url("/wp-content/themes/axel-springer-preis/img/icon-fb.svg");

  background-repeat: no-repeat;
  background-position: center;

  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}
.icon-insta { 
  background-image: url("/wp-content/themes/axel-springer-preis/img/icon-insta.svg");

  background-repeat: no-repeat; 
  background-position: center;

  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}

.clearfix::before,
.clearfix::after {
  content: " ";
  display: table;
}

.clearfix::after {
  clear: both;
}

.clearfix {
  *zoom: 1; /* Für den IE6 und IE7 */
}

@media screen and (max-width: $tabletsize) {
  .col {
    width: 100% !important;
    margin: 0 !important;
  }
}

.four-three {
  position: relative;
  overflow: hidden;
}
.four-three:before {
  display: block;
  content: " ";
  width: 100%;
  padding-top: 67%;
}
.four-three > * {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/*****
*quick fix
*****/

.cta-custom {
  &:hover {
    text-decoration: none !important;
    background-color: blue !important;
    color: #f0f !important;
  }
}


.wp-block-embed{
  margin: 0;
}
.wp-block-embed__wrapper{
  aspect-ratio: 16 / 9;

  iframe{
    width:100%;
    height:100%;
  }
}

.wp-block-image{

  &.size-large{
    margin: 0;
    width:100%;
    img{
      width:100%; 
    }
  }
}




h2.asp-teaser {
    font-weight:400;
    margin: 0;
    padding-top: 0 !important;
    padding-bottom:30px !important;
}


p.announcement{
  background-color: #0000ff;
  margin: 0;
  font-size: 20px; 
  padding-left: 30px;
  padding-right: 30px;
  width: auto;
  a{
    text-decoration: underline;
    &:hover{
      text-decoration: none;
      color:white;
    }
  }
}
h5.announcement,
h2.announcement{
  background-color: #0000ff;
  margin: 0;
  padding-left: 30px !important; 
  padding-right: 30px !important;
  width: auto;
}


.nopaddingbottom{
  padding-bottom: 0 !important; 
}


.page-id-1324 {
  .meta{
    // font-weight: 700 !important;
    font-size:12pt;
  }
 }

 .nominierung-single .entry .ui-accordion-header-icon {
  top:auto !important; 
 } 

 span.ui-accordion-header-icon {margin: 0 !important;}


 .wrapheader {
  position: relative;
  display: flex !important;
  align-items:center;
 }
 .ui-accordion-header-icon{ 
  background-image: url('../../img/angle-down-solid.svg');
  background-position:center; 
  background-repeat: no-repeat;
  background-size:contain;
  width:16px !important;  
  height:25px; 
  position: absolute;
  right: 0;
  margin: 0 !important;
  // top: 63px;
  transition: all 300ms ease-in 0s;
  transform: rotate(0deg);
}
.ui-accordion-header-active {
  .ui-accordion-header-icon{ 
//rotate
transform: rotate(180deg);
  }
}


.award-intro-wrapper{
  display:flex;
  justify-content:center;
  // margin-top: 30px;

  // margin-bottom: -50px;
  // margin-left: 20px;
}

.award-intro{
  background-color:#ff00ff;
  padding:20px;
  padding-bottom:20px;
  text-align:center;
  // transform: rotate(-8deg);
  a, p{
    // font-size:24px;
    font-size: 24px;
    
  }
  a{color:white;text-decoration: underline;
  &:hover{
    color:white;text-decoration: none;
  }}
}


.home.page-template-default,
.single-jahrgaenge{
.fancybox-stage{
  max-width: 1000px;
  margin: auto;
  max-height: 80%;
}
.fancybox-content p{
  margin: 20px;
} 
}

.page-id-1494,
.page-id-9,
.page-id-945{
  .inner-wrapper{}
}


#lauftext-wrapper {
  width: 100%;
  height: 60px;
  overflow: hidden;
  position: relative;
 background-color:#ff00ff;
 display:flex;

 align-items: center;
}
#lauftext {
  color:white;
  position: absolute;
  white-space: nowrap; 
  will-change: transform;
  font-weight: 700;
  font-size: 20px;
}

