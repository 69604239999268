// @font-face {
//     font-family: 'Mont AS Pro';
//     src: url('../../fonts/as/font/MontASPro-ExtraLight.woff2') format('woff2'),
//         url('../../fonts/as/font/MontASPro-ExtraLight.woff') format('woff');
//     font-weight: 200;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Mont AS Pro';
//     src: url('../../fonts/as/font/MontASPro-BoldItalic.woff2') format('woff2'),
//         url('../../fonts/as/font/MontASPro-BoldItalic.woff') format('woff');
//     font-weight: bold;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Mont AS Pro';
//     src: url('../../fonts/as/font/MontASPro-SemiBoldItalic.woff2') format('woff2'),
//         url('../../fonts/as/font/MontASPro-SemiBoldItalic.woff') format('woff');
//     font-weight: 500;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Mont AS Pro';
//     src: url('../../fonts/as/font/MontASPro-Regular.woff2') format('woff2'),
//         url('../../fonts/as/font/MontASPro-Regular.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Mont AS Pro';
//     src: url('../../fonts/as/font/MontASPro-Thin.woff2') format('woff2'),
//         url('../../fonts/as/font/MontASPro-Thin.woff') format('woff');
//     font-weight: 100;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Mont AS Pro';
//     src: url('../../fonts/as/font/MontASPro-LightItalic.woff2') format('woff2'),
//         url('../../fonts/as/font/MontASPro-LightItalic.woff') format('woff');
//     font-weight: 300;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Mont AS Pro';
//     src: url('../../fonts/as/font/MontASPro-HeavyItalic.woff2') format('woff2'),
//         url('../../fonts/as/font/MontASPro-HeavyItalic.woff') format('woff');
//     font-weight: 900;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Mont AS Pro';
//     src: url('../../fonts/as/font/MontASPro-Light.woff2') format('woff2'),
//         url('../../fonts/as/font/MontASPro-Light.woff') format('woff');
//     font-weight: 300;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Mont AS Pro';
//     src: url('../../fonts/as/font/MontASPro-ExtraLightItalic.woff2') format('woff2'),
//         url('../../fonts/as/font/MontASPro-ExtraLightItalic.woff') format('woff');
//     font-weight: 200;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Mont AS Pro';
//     src: url('../../fonts/as/font/MontASPro-ThinItalic.woff2') format('woff2'),
//         url('../../fonts/as/font/MontASPro-ThinItalic.woff') format('woff');
//     font-weight: 100;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Mont AS Pro';
//     src: url('../../fonts/as/font/MontASPro-BlackItalic.woff2') format('woff2'),
//         url('../../fonts/as/font/MontASPro-BlackItalic.woff') format('woff');
//     font-weight: 900;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Mont AS Pro Book';
//     src: url('../../fonts/as/font/MontASPro-Book.woff2') format('woff2'),
//         url('../../fonts/as/font/MontASPro-Book.woff') format('woff');
//     font-weight: 300;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Mont AS Pro';
//     src: url('../../fonts/as/font/MontASPro-RegularItalic.woff2') format('woff2'),
//         url('../../fonts/as/font/MontASPro-RegularItalic.woff') format('woff');
//     font-weight: normal;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Mont AS Pro';
//     src: url('../../fonts/as/font/MontASPro-Black.woff2') format('woff2'),
//         url('../../fonts/as/font/MontASPro-Black.woff') format('woff');
//     font-weight: 900;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Mont AS Pro Hairline';
//     src: url('../../fonts/as/font/MontASPro-Hairline.woff2') format('woff2'),
//         url('../../fonts/as/font/MontASPro-Hairline.woff') format('woff');
//     font-weight: 100;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Mont AS Pro';
//     src: url('../../fonts/as/font/MontASPro-Heavy.woff2') format('woff2'),
//         url('../../fonts/as/font/MontASPro-Heavy.woff') format('woff');
//     font-weight: 900;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Mont AS Pro Hairline';
//     src: url('../../fonts/as/font/MontASPro-HairlineItalic.woff2') format('woff2'),
//         url('../../fonts/as/font/MontASPro-HairlineItalic.woff') format('woff');
//     font-weight: 100;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Mont AS Pro';
//     src: url('../../fonts/as/font/MontASPro-SemiBold.woff2') format('woff2'),
//         url('../../fonts/as/font/MontASPro-SemiBold.woff') format('woff');
//     font-weight: 500;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Mont AS Pro Book';
//     src: url('../../fonts/as/font/MontASPro-BookItalic.woff2') format('woff2'),
//         url('../../fonts/as/font/MontASPro-BookItalic.woff') format('woff');
//     font-weight: 300;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Mont AS Pro';
//     src: url('../../fonts/as/font/MontASPro-Bold.woff2') format('woff2'),
//         url('../../fonts/as/font/MontASPro-Bold.woff') format('woff');
//     font-weight: bold;
//     font-style: normal;
//     font-display: swap;
// }




/* FONTS */

@font-face {
    font-family: 'Aller';
    src: url('../../fonts/aller_rg-webfont.eot');
    src: url('../../fonts/aller_rg-webfont.eot?#iefix') format('embedded-opentype'), url('../../fonts/aller_rg-webfont.woff') format('woff'), url('../../fonts/aller_rg-webfont.ttf') format('truetype'), url('../../fonts/aller_rg-webfont.svg') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Aller';
    src: url('../../fonts/aller_bd-webfont.eot');
    src: url('../../fonts/aller_bd-webfont.eot?#iefix') format('embedded-opentype'), url('../../fonts/aller_bd-webfont.woff') format('woff'), url('../../fonts/aller_bd-webfont.ttf') format('truetype'), url('../../fonts/aller_bd-webfont.svg') format('svg');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'Aller';
    src: url('../../fonts/aller_bdit-webfont.eot');
    src: url('../../fonts/aller_bdit-webfont.eot?#iefix') format('embedded-opentype'), url('../../fonts/aller_bdit-webfont.woff') format('woff'), url('../../fonts/aller_bdit-webfont.ttf') format('truetype'), url('../../fonts/aller_bdit-webfont.svg') format('svg');
    font-weight: bold;
    font-style: italic;
  }
  @font-face {
    font-family: 'Aller';
    src: url('../../fonts/aller_it-webfont.eot');
    src: url('../../fonts/aller_it-webfont.eot?#iefix') format('embedded-opentype'), url('../../fonts/aller_it-webfont.woff') format('woff'), url('../../fonts/aller_it-webfont.ttf') format('truetype'), url('../../fonts/aller_it-webfont.svg') format('svg');
    font-weight: normal;
    font-style: italic;
  }
  @font-face {
    font-family: 'Aller';
    src: url('../../fonts/aller_lt-webfont.eot');
    src: url('../../fonts/aller_lt-webfont.eot?#iefix') format('embedded-opentype'), url('../../fonts/aller_lt-webfont.woff') format('woff'), url('../../fonts/aller_lt-webfont.ttf') format('truetype'), url('../../fonts/aller_lt-webfont.svg') format('svg');
    font-weight: 300;
    font-style: normal;
  }
  


h1{
    color:$black;
    font-family: "Aller",sans-serif;
    padding-top:30px;
    padding-bottom:60px;
    font-size:40pt;
    line-height:auto;
    font-weight:200;
    margin:0 !important;
}

h2 {
    font-size: 16pt;
    text-transform: none;
    margin-top: 40px;
    font-weight: 600;
    line-height: 33px;
}

.punchline{
    h1{
        margin:0;
 
        font-weight:300;
        text-align:center;
        font-size:40pt;
      
        
    }
    span{
      

    }
    .year-selector{
        font-weight: 600;
    }
    p{
        font-weight:200;
        font-size:12pt;
    }
}




@media screen and (max-width: $tabletsize) {
    .punchline{
        h1{
            font-size: 26pt;
        }
        .intro{
            display:block;
        }
    }
}





// h2{
//     color:$black;
//     font-family: "Aller",sans-serif;

//     font-size:24pt;
//     line-height:auto;
//     font-weight:400;
//     text-transform:uppercase;
// }

// h2 {
//     color:$black;
//     font-family: "Aller",sans-serif;

//     font-size: 16pt;
//     text-transform: none;
//     // margin-top: 0;
//     font-weight: 600;
// }

h2{
    font-size: 18pt;
    margin-bottom: 0;
    color: black;
    margin-top: 40px;
    font-weight: 600;
}

h3{
    color:$black;
    font-family: "Aller",sans-serif;

    font-size:12pt;
    line-height:auto;
    font-weight:400;
    text-transform:uppercase;
}



li,
p{
    font-family: "Aller",sans-serif;
    color:$black;
    font-size:12pt;
    line-height:auto;
    font-weight:400;
    letter-spacing:1pt;
    line-height: 22pt;
    // width: auto !important;
 
}

.fancybox-caption{
    font-family: "Aller",sans-serif;
    color:white;
    font-size:12pt;

}

p.meta{
    color:$grey;
    font-size:10pt;
}
p a,
a{
    font-family: "Aller",sans-serif;

    color:$contrastcolor;

    line-height:auto;
    font-weight:400;
    letter-spacing:1pt;
 
    text-decoration: none;
  
    &:hover{
        color:$contrastcolor;
    }
}





nav{
    ul{
        list-style:none;
        li{
            float:left;
            margin-left:20px;
            margin-right:20px;
            a{
                font-family: "Aller",sans-serif;
                font-weight:100;
                color:$black;
                font-size:11pt;
                line-height:auto;
           
                text-decoration:none;
            }
        }
    }
  
}



h5{
    font-size:14pt;
    font-weight: 400;
    letter-spacing: 1pt;
    line-height: 22pt;
}


@media screen and (max-width: $tabletsize) {
  
}