/** 
* Cookie Law Plugin Wordpress CSS by Freunde der Interaktion GmbH
* https://de.wordpress.org/plugins/eu-cookie-law/
*
**/

.pea_cook_wrapper{
    border-radius:0px !important;
    width:80% !important;
    left:10% !important;
    right:10% !important;
    margin:0% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    left: 0 !important;
    right: 0 !important; 
    a, p{
        font-weight:200 !important;
        color:white !important;
        font-size:11pt !important;
        letter-spacing: normal !important;
    }
    a{
        border-bottom: 1px solid !important;
    }
    button.pea_cook_btn {
        background: #fff !important;
        border: none !important;
        border-radius: 0 !important;
        box-shadow: none !important;
        padding: 14px 0 !important;
        outline:none !important;
        color: $contrastcolor; //custom
        width: 120px !important;
        
    }
}

@media screen and (max-width: $tabletsize) {
    .pea_cook_wrapper{
        button.pea_cook_btn {
            display: block !important;
            margin: auto !important;
            margin-top: 25px !important;
            width: 200px !important;
        }
    }
}