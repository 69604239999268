.nominierungen {
    margin-bottom: 150px;
h2{     
     margin: 0;
     display: block;
    a{
    font-size: 18pt;
     margin: 0;
    color: black;
    // margin-top: 40px;
    font-weight: 600;
    display: block;
    &:hover{
        color:blue;
    }
}
} 
.entry{
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
    &:first-child {
        border-top: 1px solid #ddd;
    }
    .ui-accordion-header{
        position:relative;

    }

 
}


}

.nominierung-single{
    .author-entry{
        display:flex;
        justify-content:space-between;
    }
    .entry-content{
        width:calc(100% - 140px ); 
    }
    .entry{
        position: relative;
        .bg-image-author{
            width:100px;
            height:100px;
            display: block;
            float: left;
            margin-right: 30px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
        h2{
            margin-top: 35px; 
        }
     .ui-accordion-header-icon{ 
            background-image: url('../../img/angle-down-solid.svg');
            background-position:center; 
            background-repeat: no-repeat;
            background-size:contain;
            width:16px !important;  
            height:25px;
            position: absolute;
            right: 0;
            margin: 0 !important;
            top: 63px;
            transition: all 300ms ease-in 0s;
            transform: rotate(0deg);
        }
        .ui-accordion-header-active {
            .ui-accordion-header-icon{ 
          //rotate
          transform: rotate(180deg);
            }
        }
    }
    .author-image{ 
        width:100px;
        height:100px;
        background-size: cover;
        background-position: center; 
        background-repeat: no-repeat;
    }
    .content-text{
        max-width:900px;
    }
    h2{
    color:blue; 
    }
}