#accordion{
    color:$black;

    span{
        width:100%;
        display:block;
        outline:none;
        margin-top:20px;
        margin-bottom:20px;
       
        cursor:pointer;
        img{
            float:left;
            margin-right:30px;
            width: 100px;
            // border-radius: 50%;
            
        }
        h2{
            font-size:18pt;
            margin-bottom:0;
            color:black;
            margin-top: 40px;
            font-weight:600;
        }
        .meta{
            color:black;
            font-weight:200;
        }
     
    }
    p{
        margin:0;
        padding:0;
       
        // padding-left: 230px;
        padding-bottom: 40px;
    }
    .entry{
        border-bottom:1px solid $lightgrey;
        &:first-child{
            border-top:1px solid $lightgrey;
            }
        &:last-child{
        border-bottom:none;
        }
    }
}



i { 
    position:absolute;
  right: 20px;
  top:14px;
    -webkit-transition:all 300ms ease-in 0s;
    -moz-transition: all 300ms ease-in 0s;
    -o-transition: all 300ms ease-in 0s;
  transition: all 300ms ease-in 0s;}

.ui-state-active i {
    color: #ACD4CE;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}


@media screen and (max-width: $mobilesize) {

    #accordion{
        p{padding-left:0;}
    }
}