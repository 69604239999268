.button-wrapper{
display: flex;
justify-content: center;
background-color:$contrastcolor;
padding-top:20px;
padding-bottom:20px;
a.btn{
    padding: 0;
    display: block;
    min-width: 140px;
    padding-left: 15px;
    padding-right: 15px;
    height: 45px;
    font-weight: bold;

    background-color: $pink;
    border: 1px solid $pink;
    color: #fff;
    line-height: 45px;
    display: inline-block;
    text-align: center; 
    margin-top: 5px;
    margin-bottom: 5px;
    -webkit-transition: background .3s;
    transition: background .3s;
    cursor:pointer;
    &:hover{
        padding-top: 0;  
        padding-bottom: 0;  
        // color: white;
        background-color: $contrastcolor;
    }
} 
}