footer{
   
        //margin-top:100px;
        min-height:430px;
        border-top:1px solid $lightgrey;
        background-color: rgba(255,255,255,0.4);
     
        margin-top: 100px;

        position: relative;
     
    
        a,
    p{
        color:$grey;
        margin-left:30px;
        font-size:10pt;
        
    }

    li{
        padding:0;
    }
    
    h3{
        margin-left:30px;
    }

    ul{
        list-style:none;
        padding:0;
    }

       
        .asa-logo{
            background-image:url('/wp-content/themes/axel-springer-preis/img/freetech-logo-nav.png');
  
            background-repeat: no-repeat;
            background-position:center;
            
            margin-left:30px;
            width:60%;
            height:100px;
         
            -webkit-background-size: contain;
            -moz-background-size: contain;
            -o-background-size: contain;
            background-size: contain; 
        }

}